<template>
    <div id="eneTrend"
      ref="eneTrend"
      v-loading="loading"
      :element-loading-text="$t('alarm.a0')">
        <div class="header">
            <my-headertitle>{{ $t("energy.enedata334") }}</my-headertitle>
            <div style="padding:5px;">
              <!-- <span>{{ $t("energy.enedata336") }}</span> -->
              <a-radio-group v-model="kind" button-style="solid" @change="kindChange"  :title="$t('energy.enedata336')">
                <a-radio-button value="1">{{ $t("energy.enedata338") }}</a-radio-button>
                <a-radio-button value="2">{{ $t("energy.enedata337") }}</a-radio-button>
              </a-radio-group>
            </div>
            <div class="header-box" style="padding:5px;">
                <div style="display:flex;">
                    <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata341") }}</span> -->
                        <a-select v-model="pointGrId" style="width: 240px" @change="handleEneChange"
                          :placeholder="$t('energy.enedata341')" :title="$t('energy.enedata341')">
                            <a-select-option :title="itme.text"
                                v-for="(itme, index) in points"
                                :key="index"
                                :value="itme.no"

                                >
                                {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>

                    <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata343") }}</span> -->
                        <a-select v-model="signal" style="width: 250px;" :disabled="pointGrId ===''"
                        :placeholder="$t('energy.enedata343')"   :title="$t('energy.enedata343')">
                            <a-select-option :title="itme.text"
                                v-for="(itme, index) in signals"
                                :key="index"
                                :value="itme.no"

                                >
                                {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>

                    <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata339") }}</span> -->
                        <a-date-picker
                          v-model="dateFrom"
                          :disabled-date="disabledStartDate"
                          :allowClear="false"
                          format="YYYY/MM/DD"
                          @openChange="handleStartOpenChange"
                          @change="handleCalendarChange"
                          :title="$t('energy.enedata339')"
                        />
                    </div>

                    <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata340") }}</span> -->
                        <a-date-picker
                          v-model="dateTo"
                          :disabled-date="disabledEndDate"
                          :allowClear="false"
                          format="YYYY/MM/DD"
                          :open="endOpen"
                          @openChange="handleEndOpenChange"
                          :title="$t('energy.enedata340')"
                        />
                    </div>
                </div>
                <a-button type="primary" @click="search">{{ $t("energy.enedata006") }}</a-button>
              </div>
        </div>
        <div class="main" ref="main">
            <!-- <my-tabletitle>{{ $t("energy.enedata335") }}</my-tabletitle> -->
            <a-table
              :columns="columns"
              :dataSource="data"
              :scroll="size"
              :pagination="pagination" >
            <!-- :rowKey="(record) => record.id" -->
            </a-table>
        </div>
        <div class="footer">
            <ul class="express">
              <li class="expressli">
                <span>{{ $t("energy.enedata378") }}</span>
              </li>
              <li class="expressli">
                <span>{{ $t("energy.enedata379") }}</span>
              </li>
            </ul>
            <a-button type="primary" @click="csvout" style="margin-left:10px;" v-if="this.$route.query.id!=163">{{ $t("energy.enedata042") }}</a-button>

        </div>
    </div>
</template>
<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import {getEnergyTrendList,getEnergyHourlyList,downloadEnergyCSVDataWithURL} from "../../api/energy";
import { downloadUrl } from "../../utils/utils";
import moment from "moment";
export default {
  name: "eneTrendRecord",
  data() {
    return {
      loading: false,
      kind:'1',
      pointGrId:undefined,
      points:[],
      signal:undefined,
      signals:[],
      dateFrom:moment(),
      dateTo:moment(),
      data: undefined,
      columns: [
        {dataIndex: 'no',              title: "No."	          ,width:80,
        customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`},
        // {dataIndex: 'pointGrId',       title: "设备编号"	     ,width:150},
        {dataIndex: 'pointGrName',     title: this.$t('energy.enedata341')     ,width:150},//"设备名称"
        // {dataIndex: 'pointId',         title: "信号点ID"       ,width:150},
        {dataIndex: 'tagPoint',         title:  this.$t('energy.enedata342'),width:100},//"信号编号"
        {dataIndex: 'pointName',       title: this.$t('energy.enedata343')      ,width:150},//"信号名称"
        {dataIndex: 'date',            title:this.$t('energy.enedata017')         ,width:80},//"日期"
        {dataIndex: 'time',            title: this.$t('energy.enedata016')          ,width:80},//"时间"
        {dataIndex: 'presentValue',    title: this.$t('energy.enedata105')         ,width:100},//"计量值"
        {dataIndex: 'engineeringUnits',title: this.$t('energy.enedata113')     ,width:80},//"计量单位"
      ],
      fontClass:'',
      size: { x: 1000, y: 240 },
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示100条数据
        showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1;
          this.pagination.pageSize = pageSize;
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
        },
      },
      pointMap:null,
      endOpen:null,
      startDate: undefined,
      stime:' 00:00:00',
      etime:' 23:59:59',
    };
  },
  // 获取警报页楼层/租户选择列表
  created() {

  },
  activated(){
    this.initPage();
    this.tableSize();
    window.addEventListener("resize", this.tableSize);
  },
  mounted() {

  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  computed: {

  },
  methods: {
      kindChange(e){
          console.log('kindChange',e);
          console.log('kindChange',this.kind);
          this.data = new Array();
          this.pointGrId=undefined;
          this.signal=undefined;
          this.signals = [];
          this.initPage();
      },
      handleEneChange(e){
          console.log('handleEneChange',e);
          if(e===''){
              this.signals = new Array();
              this.signal  = '';
          }else{
              this.signal = '';
              this.signals = new Array();
              if( this.pointMap.get(e)){
                  this.signals = this.pointMap.get(e);
                  console.log('this.signals',this.signals);
              }
          }
      },
      initPage(){
          let param = {
              action:0,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              tenantId: 0,
              logType:this.kind,
          };

          this.loading = true;
          console.log('InitPage param',param);
          getEnergyTrendList(param)
            .then((res) => {
                console.log('InitPage result',res);
                this.loading = false;
                this.points = res.data.filterData;
                if(this.pointMap==null)
                    this.pointMap = new Map();
                this.points.forEach(element => {
                    if(element.no == ''){
                      element.no == '0';
                    }
                    this.pointMap.set(element.no, element.children);
                });
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });


      },
      search(){
          let end = new moment(this.dateTo);
          let end2 = end.add(1,'days');
          let param = {
              action:1,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              tenantId: 0,
              star: this.dateFrom.format("YYYY-MM-DD") + this.stime,
              end:  end2.format("YYYY-MM-DD") + this.stime,
              selDevice: this.pointGrId,
              selSignal: this.signal
          };
          // console.log(data);
          if(this.kind === '1'){
              this.loading = true;
              console.log('GetEnergyTrendList param',param);
              getEnergyTrendList(param)
                .then((res) => {
                    console.log('getEnergyTrendList result',res);
                    // console.log("data", data);
                    this.loading = false;
                    this.data = res.data.data;
                    //this.data = data;
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
          }else if(this.kind === '2'){
            this.loading = true;
              console.log('GetEnergyHourlyList param',param);
              getEnergyHourlyList(param)
                .then((res) => {
                    console.log('GetEnergyHourlyList result',res);
                    // console.log("data", data);
                    this.loading = false;
                    this.data = res.data.data;
                    //this.data = data;
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err);
                });
          }

      },
      csvout(){
          let end = new moment(this.dateTo);
          let end2 = end.add(1,'days');
          let param = {
              action:1,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              tenantId: 0,
              star: this.dateFrom.format("YYYY-MM-DD") + this.stime,
              end:  end2.format("YYYY-MM-DD") + this.etime,
              selDevice: this.pointGrId,
              selSignal: this.signal
          };
          // console.log(data);
          console.log(`📝[Print](${new Date().toLocaleString()}): 👉`, '( [button] - csvout ) [-->],', this.kind);
          if(this.kind === '1'){
              this.loading = true;
              console.log('downloadEnergyCSVDataWithURL param',param);
              downloadEnergyCSVDataWithURL('bivale/energy/history/trend/download',param)
              .then((res) => {
                console.log(`📝[Print](${new Date().toLocaleString()}): 👉`, '( [button] - csvout - res ) [-->],', this.kind, res);
                // if(res.)
                let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata338')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
                console.log("downloadEnergyCSVDataWithURL Result",res);
                downloadUrl(res,name);
              })
              .catch((err) => {
                  this.loading = false;
                  this.$message.error(this.$t('energy.enedata376'));
                  console.log(err);
              })
              .finally(()=>{
                  this.loading = false;
              });

          }else if(this.kind === '2'){
              this.loading = true;
              console.log('downloadEnergyCSVDataWithURL param',param);
              downloadEnergyCSVDataWithURL('bivale/energy/history/logging/download',param)
              .then((res) => {
                    console.log(`📝[Print](${new Date().toLocaleString()}): 👉`, '( [button] - csvout - res ) [-->],', this.kind, res);
                  let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata337')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
                  console.log("downloadEnergyCSVDataWithURL Result",res);
                  downloadUrl(res,name);
              })
              .catch((err) => {
                  this.loading = false;
                  this.$message.error(this.$t('energy.enedata376'));
                  console.log('downloadEnergyCSVDataWithURL',err);
              })
              .finally(()=>{
                  this.loading = false;
              });
          }
      },
      moment,
      // 表格自适应方法
      tableSize() {
          let height = this.$refs.main.clientHeight;
          let tableHeight = height - 100;
          this.size.y = tableHeight;
      },
      // 初始日期/时间选择变化回调
      handleCalendarChange(value, mode) {
        this.startDate = moment(value._d).format("YYYY/MM/DD");
        this.dateTo = null;
      },
        // 初始日期/时间的日期不可选范围
      disabledStartDate(startValue) {
        const endValue = this.dateTo;
        if (!startValue || !endValue) {
          return false;
        }
        return (
          startValue.valueOf() > endValue.valueOf() ||
          startValue >= moment().endOf()
        );
      },
      // 终止日期/时间的日期不可选范围
      disabledEndDate(endValue) {
        const startValue = this.dateFrom;
        let startDate = this.startDate;
        if (!endValue || !startValue) {
          return false;
        }
        return (
          startValue.valueOf() >= endValue.valueOf() ||
          endValue >= moment().endOf() ||
          endValue >= moment(new Date(startDate)).add(1, "months")
        );
      },
      // 初始日期/时间选择弹出日历和关闭日历的回调
      handleStartOpenChange(open) {
        if (!open) {
          this.endOpen = true;
        }
      },
      // 终止日期/时间选择弹出日历和关闭日历的回调
      handleEndOpenChange(open) {
        this.endOpen = open;
      },

  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
  },
}
</script>
<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#eneTrend {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  padding: 0 20px 10px;
  overflow: hidden;
}
.header-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-box button{
  text-align: right;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}

.header-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5px;
}
.header-column span {
  font-size: 14px;
}
.main {
  height: calc(100% - 140px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.footer button{margin-bottom: 10px;}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
</style>