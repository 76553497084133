<!--会议布局-->
<template>
  <div
    class="conferenceLayout"
    v-loading="loading"
    element-loading-text="加载中..."
  >
    <a-result v-if="src==='error'" status="500" title="500" sub-title="您的账户无权限访问，请联系管理员开通ELE PLUS系统权限！">
        
    </a-result>
    <iframe v-else
        ref="iframe"
        :src="src"
        id="childPage"
        width="100%"
        height="100%"
        frameborder="0"
        @load="handleMessage"
      ></iframe>
  </div>
</template>

<script>
import { homePageAddress,getRmcsUrl } from "../../api/user";

export default {
  data() {
    return {
      src: "",//http://www.xzqnbyqf.top:18180/hitachibivale/#/?x-token=f0052973143149a4abad71bd84904637
      loading: false,
      param: {
        // 必填标识项
        name: "fromIframe",
        // 选填类别项，表明操作类型为登录
        type: "login",
        // 选填国际化语言项，表明父页面当前使用的语言
        lang: this.$i18n.locale,
      },
    };
  },
  mounted() {
    this.loading = true;
    const clientId = this.$route.query.cid;
    const siteGrId = this.$route.query.gid;
    const siteId = this.$route.query.sid;
    const data = {
      clientId,
      siteGrId,
      siteId,
    };
    console.log(data);
    getRmcsUrl(data)
      .then((res) => {
        console.log(res);
        if(res.code == 0)
          {
            this.src = res.data.url;
          }else{
            this.src = 'error';
          }
        this.loading = false;
      })
      .catch((err) => {
        this.src = 'error';
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    handleMessage() {
      const param = this.param;
      console.log(param);
      let iframe = document
        .getElementById("childPage")
        .contentWindow.postMessage(param, "*");
    },
  },
};
</script>

<style scoped>
.conferenceLayout {
  width: calc(100% - 40px);
  height: calc(100% - 30px);
  overflow: hidden;
  box-shadow: 0px 0px 10px #bdbcbc;
}
</style>