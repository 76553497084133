<!--设置管理--用户信息设置-->
<template>
  <div
    id="customercenter"
    ref="customercenter"
    v-loading="loading"
    :element-loading-text="$t('UserSettings.a1')"
  >
    <div class="interface">
      <!-- 页面标题--用户设置 -->
      <!-- <div>
        <my-headertitle>{{ $t("UserSettings.a2") }}</my-headertitle>
      </div> -->
      <div class="pageTitle"> 
        <div class="pageTitle-left">
          <img src="../../../public/images/set/setindex02.png" />
          <span>{{ $t("UserSettings.a2") }}</span>
        </div>
        <div>
          <a-icon type="close" :style="{ color: 'rgba(12, 12, 12, 0.65)' }" @click="Fallback"/>
        </div>
      </div>
      <!-- 主内容区域 -->
      <div class="main">
        <!-- 侧边栏 -->
        <div class="sidebar">
          <!-- 用户类型选择 -->
          <div class="sidebar_row">
            <span>{{ $t("UserSettings.a3") }}</span>
            <a-select v-model="userType" style="width: 150px">
              <a-select-option value="1">
                {{ $t("UserSettings.a4") }}
              </a-select-option>
              <a-select-option value="2">
                {{ $t("UserSettings.a5") }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 客户选择 -->
          <div class="sidebar_row" v-if="userType == '2'">
            <span>{{ $t("UserSettings.a6") }}</span>
            <a-select v-model="clientValue" style="width: 160px">
              <a-select-option
                v-for="(item, index) in clientList"
                :value="item.id"
                :key="index"
              >
                {{ item.clientName }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 新建用户组 -->
          <!-- <div class="sidebar_row">
            <a-button icon="plus" type="primary" ghost @click="openUserSet">
              新建用户组
            </a-button>
          </div> -->
          <!-- 用户组列表 -->
          <div :class="sidebaruserlist">
            <a-menu
              v-model="userValue"
              :open-keys.sync="openKeys"
              mode="inline"
            >
              <a-sub-menu
                key="sub2"
                :title="$t('UserSettings.a4')"
                v-if="userType == '1'"
              >
                <a-menu-item
                  v-for="item in systemUserList"
                  :key="item.id"
                  @click="getUserData(item)"
                >
                  {{ item.name }}
                </a-menu-item>
              </a-sub-menu>
              <a-sub-menu v-if="userType == '2'" :title="$t('UserSettings.a5')">
                <a-sub-menu
                  :key="name"
                  v-for="(val, name) in clientMenuData"
                  :title="clientUserTitle(name)"
                >
                  <a-menu-item
                    v-for="item in val"
                    :key="item.id"
                    @click="getClientData(item)"
                  >
                    {{ item.name }}
                  </a-menu-item>
                </a-sub-menu>
              </a-sub-menu>
            </a-menu>
          </div>
        </div>
        <!-- 空界面 -->
        <div class="empty" v-if="userValue.length == 0">
          <a-empty>
            <span slot="description">{{ $t("UserSettings.a7") }}</span>
          </a-empty>
        </div>
        <!-- 用户信息数据渲染区域 -->
        <div class="index" v-else>
          <!-- 头部-->
          <div class="index_header">
            <div class="index_header_left">
              <!-- 卡片 -->
              <div class="index_header_card">
                <img src="../../../public/images/set/user.png" />
                <div class="index_heade_card_title">
                  <span>{{ basicsInfo.name }}</span>
                </div>
              </div>
              <!-- 信息 -->
              <div class="index_header_info">
                <div class="index_header_info_chunk">
                  <p>{{ $t("UserSettings.a8") }}</p>
                  <span>{{ basicsInfo.id }}</span>
                </div>
                <div class="index_header_info_chunk line">
                  <p>{{ $t("UserSettings.a9") }}</p>
                  <span>{{ basicsInfo.maxUserCount | none }}</span>
                </div>
                <div class="index_header_info_chunk line">
                  <p>{{ $t("UserSettings.a10") }}</p>
                  <span>{{ basicsInfo.userNumber }}</span>
                </div>
              </div>
            </div>
            <!-- 操作按钮 -->
            <div class="index_header_right">
              <a-button
                type="primary"
                ghost
                icon="form"
                @click="openInfoCompile"
              >
                {{ $t("UserSettings.a11") }}
              </a-button>
            </div>
          </div>
          <div class="index_main">
            <!-- 具有权限的站点 -->
            <div class="index_site" v-if="userType == '2'">
              <!-- 标题 -->
              <my-headertitle
                >{{ $t("UserSettings.a12") }}
                <template v-slot:name>
                  <a-button
                    icon="form"
                    type="primary"
                    ghost
                    @click="openSetSite"
                  >
                    {{ $t("UserSettings.a13") }}
                  </a-button>
                </template>
              </my-headertitle>
              <!-- 站点卡片表 -->
              <div class="index_site_main">
                <div
                  class="index_site_main_card"
                  v-for="(item, index) in jurisdictionData"
                  :key="index"
                >
                  <div class="index_site_main_card_title">
                    <span :title="item.siteName">{{ item.siteName }}</span>
                    <a-icon
                      type="form"
                      :style="{ fontSize: '20px', color: '#7682ce' }"
                      @click="goSiteJurisdictionSet(item)"
                    />
                  </div>
                  <div class="margin_bottom">
                    <p class="p_title">{{ $t("UserSettings.a14") }}</p>
                    <span>{{ item.siteId }}</span>
                  </div>
                  <div>
                    <p class="p_title">{{ $t("UserSettings.a15") }}</p>
                    <span>{{ item.buildingName }}</span>
                  </div>
                </div>
                <div
                  class="empty_card"
                  v-for="item in 5"
                  :key="item + 'q'"
                ></div>
              </div>
            </div>
            <!-- 用户列表 -->
            <div :class="index_user">
              <my-headertitle
                >{{ $t("UserSettings.a16") }}
                <template v-slot:name>
                  <a-button
                    icon="plus"
                    type="primary"
                    ghost
                    @click="openNewUser"
                    :disabled="userListData.length == 10"
                  >
                    {{ $t("UserSettings.a17") }}
                  </a-button>
                </template>
              </my-headertitle>
              <div class="index_user_main">
                <div
                  class="index_user_main_row"
                  v-for="(item, index) in userListData"
                  :key="index"
                >
                  <div class="index_user_main_row_user">
                    <img
                      class="index_user_main_row_user_img"
                      src="../../../public/images/set/visitor.png"
                    />
                    <div class="suer_info_container">
                      <div class="suer_info">
                        <span class="suer_info_name">{{
                          $t("UserSettings.a18")
                        }}</span>
                        <span>{{ item.name }}</span>
                        <div class="index_header_card_sign">
                          {{ `${item.userType}${$t("UserSettings.a19")}` }}
                        </div>
                      </div>
                      <div class="suer_info">
                        <img
                          class="img_email"
                          src="../../../public/images/login/login04.png"
                        />
                        <span>{{ item.mailAddress | none }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="index_header_info">
                    <div class="index_user_info_chunk">
                      <p>{{ item.loginName }}</p>
                      <span>{{ $t("UserSettings.a20") }}</span>
                    </div>
                    <div class="index_user_info_chunk line">
                      <p>
                        {{
                          item.expirationDate | valid($t("UserSettings.a87"))
                        }}
                      </p>
                      <span>{{ $t("UserSettings.a21") }}</span>
                    </div>
                    <div class="index_user_info_chunk line">
                      <p>{{ `${item.passwordExpirationTerm}D` }}</p>
                      <span>{{ $t("UserSettings.a22") }}</span>
                    </div>
                    <div class="index_user_info_chunk line">
                      <p>{{ item.authenticationCode }}</p>
                      <span>{{ $t("UserSettings.a23") }}</span>
                    </div>
                  </div>
                  <div class="index_user_main_row_icon">
                    <a-icon
                      type="form"
                      :style="{
                        fontSize: '20px',
                        color: '#7682ce',
                        marginRight: '20px',
                      }"
                      @click="openNewUser(item)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新建用户组 -->
    <a-modal
      :title="$t('UserSettings.a24')"
      centered
      :width="900"
      :visible="userSetVisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.customercenter"
      @cancel="userSetCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="userSetCancel">
          {{ $t("UserSettings.a25") }}
        </a-button>
        <a-button key="submit" type="primary">
          {{ $t("UserSettings.a26") }}
        </a-button>
      </template>
      <div class="newUser">
        <div class="newUser_title">
          <div class="newUser_name">
            <span>{{ $t("UserSettings.a27") }}</span>
            <a-input style="width: 200px" />
          </div>
          <my-headertitle>{{ $t("UserSettings.a28") }}</my-headertitle>
        </div>
        <div class="newUser_main">
          <my-tabletitle>{{ $t("UserSettings.a29") }}</my-tabletitle>
          <my-tabletitle>{{ $t("UserSettings.a30") }}</my-tabletitle>
          <my-tabletitle>{{ $t("UserSettings.a31") }}</my-tabletitle>
        </div>
      </div>
    </a-modal>
    <!-- 站点设置弹窗 -->
    <a-modal
      :title="$t('UserSettings.a32')"
      centered
      :width="650"
      :visible="siteSetVisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.customercenter"
      @cancel="siteSetCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="siteSetCancel">
          {{ $t("UserSettings.a25") }}
        </a-button>
        <a-button key="submit" type="primary" @click="siteSetDataUpate">
          {{ $t("UserSettings.a26") }}
        </a-button>
      </template>
      <div
        v-loading="siteSetLoading"
        :element-loading-text="$t('UserSettings.a1')"
      >
        <div class="siteSetmain">
          <el-tree
            :data="data"
            :props="props"
            default-expand-all
            :expand-on-click-node="false"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-if="data.treeLevel !== 'client'">
                <a-checkbox
                  v-model="data.permissLevel1"
                  :disabled="
                    data.treeLevel == 'tenant' ||
                    (data.permissType === -1 && data.treeLevel === 'site')
                  "
                  @click="siteCheckboxSet(data, 1)"
                >
                  {{ $t("UserSettings.a33") }}
                </a-checkbox>
                <a-checkbox
                  v-model="data.permissLevel2"
                  :disabled="
                    data.treeLevel == 'tenant' ||
                    (data.permissType === -1 && data.treeLevel === 'site') ||
                    data.permissLevel1 == false
                  "
                  @click="siteCheckboxSet(data, 2)"
                >
                  {{ $t("UserSettings.a34") }}
                </a-checkbox>
                <a-checkbox
                  v-model="data.permissLevel3"
                  :disabled="
                    data.treeLevel == 'tenant' ||
                    (data.permissType == -1 && data.treeLevel == 'site') ||
                    data.permissLevel1 == false ||
                    data.permissLevel2 == false
                  "
                  @click="siteCheckboxSet(data, 3)"
                >
                  {{ $t("UserSettings.a35") }}
                </a-checkbox>
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </a-modal>
    <!-- 基础信息编辑 -->
    <a-modal
      :title="$t('UserSettings.a36')"
      centered
      :width="300"
      :visible="userinfovisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.customercenter"
      @cancel="userInfoCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="userInfoCancel">
          {{ $t("UserSettings.a25") }}
        </a-button>
        <a-button key="submit" type="primary" @click="userInfoUpdate">
          {{ $t("UserSettings.a26") }}
        </a-button>
      </template>
      <div
        class="padding"
        v-loading="userLoading"
        :element-loading-text="$t('UserSettings.a1')"
      >
        <a-form-model ref="ruleForm" :model="copyBasicsInfo" :rules="rules">
          <a-form-model-item :label="$t('UserSettings.a37')" prop="name">
            <a-input
              v-model="copyBasicsInfo.name"
              :placeholder="$t('UserSettings.a38')"
            />
          </a-form-model-item>
          <a-form-model-item
            :label="$t('UserSettings.a39')"
            prop="maxUserCount"
          >
            <a-input
              v-model.number="copyBasicsInfo.maxUserCount"
              :placeholder="$t('UserSettings.a40')"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 用户列表新建用户 -->
    <a-modal
      :title="$t('UserSettings.a2')"
      centered
      :visible="newUservisible"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.customercenter"
      @cancel="newUserCancel"
      :width="800"
    >
      <template slot="footer">
        <a-button key="back" @click="newUserCancel">
          {{ $t("UserSettings.a25") }}
        </a-button>
        <a-button key="submit" type="primary" @click="newUserSet">
          {{ $t("UserSettings.a26") }}
        </a-button>
      </template>
      <div
        class="suerSet"
        v-loading="newUserloading"
        :element-loading-text="$t('UserSettings.a1')"
      >
        <a-form-model
          ref="newUserruleForm"
          :model="newUserData"
          :rules="newUserrules"
          layout="horizontal"
        >
          <a-row type="flex" justify="center" align="middle" :gutter="32">
            <!-- 账号Id -->
            <a-col :span="12">
              <a-form-model-item
                :label="$t('UserSettings.a41')"
                prop="loginName"
              >
                <a-input
                  v-model="newUserData.loginName"
                  :placeholder="$t('UserSettings.a42')"
                />
              </a-form-model-item>
            </a-col>
            <!-- 账号 -->
            <a-col :span="12">
              <a-form-model-item
                :label="$t('UserSettings.a43')"
                prop="userName"
              >
                <a-input
                  v-model="newUserData.userName"
                  :placeholder="$t('UserSettings.a44')"
                />
              </a-form-model-item>
            </a-col>
            <!-- 验证码 -->
            <a-col :span="12">
              <a-form-model-item :label="$t('UserSettings.a23')" prop="code">
                <a-input
                  v-model="newUserData.code"
                  :placeholder="$t('UserSettings.a23')"
                />
              </a-form-model-item>
            </a-col>
            <!-- 账户有效期 -->
            <a-col :span="12">
              <a-form-model-item
                :label="$t('UserSettings.a47')"
                prop="expirationDate"
              >
                <a-date-picker
                  v-model="newUserData.expirationDate"
                  :placeholder="$t('UserSettings.a48')"
                />
              </a-form-model-item>
            </a-col>
            <!-- Email地址 -->
            <a-col :span="15">
              <a-form-model-item :label="$t('UserSettings.a49')">
                <a-input
                  v-model="newUserData.email"
                  :placeholder="$t('UserSettings.a50')"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="9"> </a-col>
            <!-- 修改密码 -->
            <a-col :span="12" style="padding: 20px 16px">
              <a-checkbox v-model="newUserData.isPwd" :disabled="pwdDisabled"
                >{{ $t("UserSettings.a51") }}
              </a-checkbox>
            </a-col>
            <a-col :span="12"> </a-col>
            <!-- 密码1 -->
            <a-col :span="6" v-if="newUserData.isPwd">
              <a-form-model-item :label="$t('UserSettings.a52')" prop="pwd1">
                <a-input
                  v-model="newUserData.pwd1"
                  :placeholder="$t('UserSettings.a53')"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="newUserData.isPwd">
              <a-form-model-item :label="$t('sidebar.a27')" prop="pwd1Check">
                <a-input
                  v-model="newUserData.pwd1Check"
                  :placeholder="$t('UserSettings.a53')"
                />
              </a-form-model-item>
            </a-col>
            <!-- 密码2 -->
            <a-col :span="6" v-if="newUserData.isPwd">
              <a-form-model-item :label="$t('UserSettings.a54')" prop="pwd2">
                <a-input
                  v-model="newUserData.pwd2"
                  :placeholder="$t('UserSettings.a55')"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" v-if="newUserData.isPwd">
              <a-form-model-item :label="$t('sidebar.a28')" prop="pwd2Check">
                <a-input
                  v-model="newUserData.pwd2Check"
                  :placeholder="$t('UserSettings.a55')"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle.vue";
import tabletitle from "../../components/Title/tabletitle";
import {
  getsysusergr,
  getcustlist,
  getclientallusergr,
  getusergrwithsite,
  getUserList,
  allbuildingwithsiteandtenant,
  updategr,
  userUpdate,
  setusergrsiteandsitegrpermision,
} from "../../api/setManagement";
import { getRsa } from "../../api/user";
import AES from "@/utils/aes.js";
import { Base64 } from "js-base64";
import moment from "moment";
const ex = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/;
export default {
  name: "customercenter",
  data() {
    let pass1Validate1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));//'Please input the password'
      } else  if (!ex.test(value)) {
        callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      } else {
        if (this.newUserData.pwd1Check !== '') {
          this.$refs.newUserruleForm.validateField('pwd1Check');
        }
        if(this.newUserData.pwd2 !== ''){
          this.$refs.newUserruleForm.validateField('pwd2');
        }
        callback();
      }
    };
    let pass1Validate2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));
      } else  if (!ex.test(value)) {
       callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      } else if (value !== this.newUserData.pwd1) {
        callback(new Error(this.$t("sidebar.a23")));//Two inputs don't match! 密码两次输入不匹配！
      } else {
        callback();
      }
    };

    let pass2Validate1 = (rule, value, callback) => {
       if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));
      } else if (value === this.newUserData.pwd1) {
        callback(new Error(this.$t("sidebar.a25")));
      } else  if (!ex.test(value)) {
       callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      }else {
        if (this.newUserData.pwd2Check !== '') {
          this.$refs.newUserruleForm.validateField('pwd2Check');
        }
        callback();
      }
    };
    let pass2Validate2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));
      } else  if (!ex.test(value)) {
        callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      } else if (value !== this.newUserData.pwd2) {
        callback(new Error(this.$t("sidebar.a23")));//Two inputs don't match! 密码两次输入不匹配！
      } else {
        callback();
      }
    };


    let checkPending;
    let checkAge = (rule, value, callback) => {
      console.log(value);
      clearTimeout(checkPending);
      if (!value) {
        return callback(new Error(this.$t("UserSettings.a56")));
      }
      checkPending = setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error(this.$t("UserSettings.a57")));
        } else {
          if (value < this.basicsInfo.userNumber || value > 20) {
            callback(new Error(this.$t("UserSettings.a58")));
          } else {
            callback();
          }
        }
      }, 500);
    };
    return {
      loading: false,
      userLoading: false,
      userType: "1",
      clientValue: undefined,
      clientList: [],
      systemUserList: [],
      clientMenuData: {},
      userValue: [],
      basicsInfo: {
        userNumber: "",
      },
      copyBasicsInfo: {
        name: "",
        maxUserCount: "",
      },
      userListData: [],
      jurisdictionData: [],
      setSiteData: [],
      userinfovisible: false,
      newUservisible: false,
      siteSetLoading: false,
      newUserData: {
        userId: "",
        grId: "",
        loginName: "",
        userName: "",
        email: "",
        code: "",
        isPwd: false,
        pwd1: "",
        pwd1Check: "",
        pwd2: "",
        pwd2Check: "",
        expirationDate: moment("2099-01-01"),
        aeskey: "",
      },
      newUserloading: false,
      pwdDisabled: true,
      data: [],
      props: {
        label: "name",
        children: "items",
      },
      siteSetVisible: false,
      userSetVisible: false,
      visible: false,
      openKeys: ["sub1"],
      rules: {
        name: [
          {
            required: true,
            message: this.$t("UserSettings.a59"),
            trigger: "change",
          },
        ],
        maxUserCount: [
          {
            validator: checkAge,
            trigger: "change",
          },
        ],
      },
      form: {
        name: "",
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      newUserrules: {
        loginName: [
          {
            required: true,
            message: this.$t("UserSettings.a60"),
            trigger: "change",
          },
        ],
        userName: [
          {
            required: true,
            message: this.$t("UserSettings.a61"),
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("UserSettings.a62"),
            trigger: "change",
          },
        ],
        expirationDate: [
          {
            required: true,
            message: this.$t("UserSettings.a63"),
            trigger: "change",
          },
        ],

        pwd1: [{ required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change", },
        { validator: pass1Validate1, trigger: 'change' }],
        pwd1Check: [{ required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change", },
        { validator: pass1Validate2, trigger: 'change' }],
        pwd2: [{ required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change", },
        { validator: pass2Validate1, trigger: 'change' }],
        pwd2Check: [{ required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change", },
        { validator: pass2Validate2, trigger: 'change' }]
      },
      replaceFields: {
        children: "items",
        title: "name",
      },
      siteIds: [],
      siteGrIds: [],
      rsa: "",
    };
  },
  watch: {
    // 根据用户类型加载系统用户/客户用户列表
    userType: {
      async handler(newValue) {
        this.userValue = [];
        this.loading = true;
        switch (newValue) {
          case "1":
            getsysusergr()
              .then((res) => {
                console.log(res);
                this.loading = false;
                const { data } = res;
                this.systemUserList = [data];
              })
              .catch((err) => {
                console.log(err);
                this.loading = false;
              });
            break;
          case "2":
            await getcustlist()
              .then((res) => {
                // console.log(res);
                const { data } = res;
                const [{ id }] = data;
                this.clientValue = id;
                this.clientList = data;
              })
              .catch((err) => {
                console.log(err);
                this.loading = false;
              });
            this.getClientUserData();
            break;
          default:
            return false;
        }
      },
      immediate: true,
    },
    // 根据客户ID加载客户用户列表
    clientValue: {
      handler(newValue) {
        this.userValue = [];
        this.getClientUserData();
      },
    },
  },
  // 过滤器
  filters: {
    none(msg) {
      if (!msg) {
        return "----";
      } else {
        return msg;
      }
    },
    valid(value, val) {
      if (!value) {
        return val;
      } else {
        return value;
      }
    },
  },
  created() {
    // 获取服务端Ras公钥
    getRsa()
      .then((res) => {
        this.rsa = res.data;
        // console.log("RSA公钥", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    // 切换用户类型时更换用户列表的样式类名，动态改变用户列表容器的高
    sidebaruserlist() {
      const value = this.userType;
      return value == "1" ? "sidebar_userlist2" : "sidebar_userlist1";
    },
    index_user() {
      const value = this.userType;
      return value == "1" ? "index_user1" : "index_user2";
    },
  },
  methods: {
    moment,
    // 关闭页面
    Fallback(){
      this.$router.go(-1)
    },
    // 获取客户用户列表
    getClientUserData() {
      this.loading = true;
      const data = {
        clientId: this.clientValue,
      };
      getclientallusergr(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          const { data } = res;
          this.clientMenuData = data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = true;
        });
    },
    //
    clientUserTitle(value) {
      // console.log(value);
      switch (value) {
        case "ower":
          return this.$t("UserSettings.a67");
          break;
        case "tenant":
          return this.$t("UserSettings.a68");
          break;
        case "manager":
          return this.$t("UserSettings.a69");
          break;
        default:
          return false;
      }
    },
    // 获取系统用户数据
    getUserData(value) {
      // console.log(value);
      this.loading = true;
      this.basicsInfo = value;
      const userGrId = value.id;
      const data = {
        userGrId,
      };
      getUserList(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          const { data } = res;
          for (let i = 0; i < data.length; i++) {
            if (Boolean(data[i].expirationDate)) {
              data[i].expirationDate = moment(data[i].expirationDate).format(
                "YYYY-MM-DD"
              );
            }
          }
          this.basicsInfo.userNumber = data.length;
          this.userListData = data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 获取客户用户数据
    async getClientData(value) {
      this.basicsInfo = value;
      this.loading = true;
      const clientId = this.clientValue;
      const userGrId = value.id;
      const data = {
        clientId,
        userGrId,
      };
      // 获取权限站点数据
      await getusergrwithsite(data)
        .then((res) => {
          const { data } = res;
          this.jurisdictionData = data;
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      // 获取用户列表数据
      const date = {
        userGrId,
      };
      await getUserList(date)
        .then((res) => {
          const { data } = res;
          console.log("sdfasdf",data);
          this.userListData = data;
          this.basicsInfo.userNumber = data.length;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      // 获取站点设置数据
      await allbuildingwithsiteandtenant(data)
        .then((res) => {
          const { data } = res;
          this.recursionData(data.items);
          this.siteDataSet([data]);
          this.data = [data];
          this.loading = false;
          console.log(this.data);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 打开站点设置弹窗
    openSetSite() {
      this.siteSetVisible = true;
    },
    // 关闭站点设置弹窗
    siteSetCancel() {
      this.siteSetVisible = false;
    },
    // 关闭新建用户组弹窗
    userSetCancel() {
      this.userSetVisible = false;
    },
    // 打开基础信息编辑弹窗
    openInfoCompile() {
      const value = { ...this.basicsInfo };
      this.copyBasicsInfo = value;
      this.userinfovisible = true;
    },
    // 关闭基础信息编辑弹窗
    userInfoCancel() {
      this.userinfovisible = false;
    },
    // 基础信息更新
    userInfoUpdate() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.userLoading = true;
          const userGrId = this.copyBasicsInfo.id;
          const name = this.copyBasicsInfo.name;
          const maxCount = this.copyBasicsInfo.maxUserCount;
          const data = {
            userGrId,
            name,
            maxCount,
          };
          console.log("data", data);
          updategr(data)
            .then((res) => {
              // console.log(res);
              this.userLoading = false;
              if (res.errorCode == "00") {
                this.basicsInfo.maxUserCount = maxCount;
                this.basicsInfo.name = name;
                this.$message.success(res.msg);
                this.userInfoCancel();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
              this.userLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 打开新建用户弹窗
    openNewUser(value) {
      // console.log(value);
      const newUserData = {
        userId: "",
        grId: "",
        loginName: "",
        userName: "",
        email: "",
        code: "",
        isPwd: true,
        pwd1: "",
        pwd2: "",
        expirationDate: null,
        aeskey: "",
      };
      // 如果打开的是新建则数据初始值为空，更新则将对应的值赋值
      // 是否修改密码，新建为禁用必须输入密码，更新则可选择是否修改密码
      if (!value.id) {
        this.newUserData = newUserData;
        this.pwdDisabled = true;
      } else {
        newUserData.userId = value.id;
        newUserData.loginName = value.loginName;
        newUserData.userName = value.name;
        newUserData.email = value.mailAddress;
        newUserData.expirationDate =
          value.expirationDate == null
            ? moment("2099-01-01")
            : moment(value.expirationDate);
        newUserData.code = value.authenticationCode;
        newUserData.isPwd = false;
        this.newUserData = newUserData;
        this.pwdDisabled = false;
      }
      this.newUservisible = true;
    },
    // 关闭新建用户弹窗
    newUserCancel() {
      this.newUservisible = false;
    },
    // 新建用户设置数据提交函数
    newUserSet() {
      this.$refs.newUserruleForm.validate((valid) => {
        if (valid) {
          this.newUserloading = true;
          const _data = JSON.stringify(this.newUserData);
          const data = JSON.parse(_data);
          data.grId = this.basicsInfo.id;
          data.expiredDate = moment(data.expirationDate).format("YYYY-MM-DD");
          if (data.isPwd) {
            const keys = AES.generatekey(16);
            data.pwd1 = AES.encrypt(data.pwd1, keys);
            data.pwd2 = AES.encrypt(data.pwd2, keys);
            let encrypt = new JSEncrypt();
            encrypt.setPublicKey(this.rsa);
            let aeskey = encrypt.encrypt(keys);
            data.aeskey = aeskey;
          }
          console.log(data);
          userUpdate(data)
            .then((res) => {
              console.log(res);
              this.newUserloading = false;
              if (res.errorCode == "00") {
                this.$message.success(res.msg);
                const userListData = this.userListData;
                const result = userListData.findIndex(
                  (row) => row.id === data.userId
                );
                userListData[result].authenticationCode = data.code;
                userListData[result].loginName = data.loginName;
                userListData[result].name = data.userName;
                userListData[result].mailAddress = data.email;
                userListData[result].expirationDate = data.expiredDate;
                this.newUserCancel();
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //跳转至权限站点页
    goSiteJurisdictionSet(value) {
      value.userGrId = this.basicsInfo.id;
      this.$router.push({
        path: "/set/siteJurisdictionSet",
        query: { id: Base64.encode(JSON.stringify(value)) },
      });
    },
    // 递归数据
    recursionData(value) {
      for (let i = 0; i < value.length; i++) {
        value[i].permissLevel = 0;
        value[i].permissLevel1 = false;
        value[i].permissLevel2 = false;
        value[i].permissLevel3 = false;
        value[i].permissType = 0;
        if (value[i].items !== undefined) {
          this.recursionData(value[i].items);
        }
      }
    },
    // 设置站点数据操作
    siteDataSet(value) {
      const jurisdictionData = this.jurisdictionData;
      const setData = value[0].items;
      for (let i = 0; i < jurisdictionData.length; i++) {
        let result = setData.findIndex(
          (list) => list.id === jurisdictionData[i].buildingId
        );
        if (Boolean(setData[result]) && Boolean(jurisdictionData[i])) {
          this.setSiteDataRecursion([setData[result]], jurisdictionData[i]);
        }
      }
    },
    // 设置站点数据遍历方法
    setSiteDataRecursion(setData, jurisdictionData) {
      for (let i = 0; i < setData.length; i++) {
        if (jurisdictionData.permissType == -1) {
          if (jurisdictionData.permissLevel == 0) {
            setData[i].permissLevel = 0;
            setData[i].permissLevel1 = false;
            setData[i].permissLevel2 = false;
            setData[i].permissLevel3 = false;
            setData[i].permissType = -1;
          } else if (jurisdictionData.permissLevel == 1) {
            setData[i].permissLevel = 1;
            setData[i].permissLevel1 = true;
            setData[i].permissLevel2 = false;
            setData[i].permissLevel3 = false;
            setData[i].permissType = -1;
          } else if (jurisdictionData.permissLevel == 2) {
            setData[i].permissLevel = 2;
            setData[i].permissLevel1 = true;
            setData[i].permissLevel2 = true;
            setData[i].permissLevel3 = false;
            setData[i].permissType = -1;
          } else if (jurisdictionData.permissLevel == 3) {
            setData[i].permissLevel = 3;
            setData[i].permissLevel1 = true;
            setData[i].permissLevel2 = true;
            setData[i].permissLevel3 = true;
            setData[i].permissType = -1;
          }
        } else {
          if (setData[i].treeLevel !== "building") {
            if (jurisdictionData.permissLevel == 0) {
              setData[i].permissLevel = 0;
              setData[i].permissLevel1 = false;
              setData[i].permissLevel2 = false;
              setData[i].permissLevel3 = false;
              setData[i].permissType = 0;
            } else if (jurisdictionData.permissLevel == 1) {
              setData[i].permissLevel = 1;
              setData[i].permissLevel1 = true;
              setData[i].permissLevel2 = false;
              setData[i].permissLevel3 = false;
              setData[i].permissType = 0;
            } else if (jurisdictionData.permissLevel == 2) {
              setData[i].permissLevel = 2;
              setData[i].permissLevel1 = true;
              setData[i].permissLevel2 = true;
              setData[i].permissLevel3 = false;
              setData[i].permissType = 0;
            } else if (jurisdictionData.permissLevel == 3) {
              setData[i].permissLevel = 3;
              setData[i].permissLevel1 = true;
              setData[i].permissLevel2 = true;
              setData[i].permissLevel3 = true;
              setData[i].permissType = 0;
            }
          } else {
            setData[i].permissLevel = 0;
            setData[i].permissLevel1 = false;
            setData[i].permissLevel2 = false;
            setData[i].permissLevel3 = false;
            setData[i].permissType = 0;
          }
        }
        if (Boolean(setData[i].items)) {
          this.setSiteDataRecursion(setData[i].items, jurisdictionData);
        }
      }
    },
    // 站点设置多选框选择操作
    siteCheckboxSet(value, type) {
      // console.log(value);
      // 添加定时器延时获取checkbox状态值
      let times = setTimeout(() => {
        // 判断是事件是显示/操作/设置操作
        if (type == 1) {
          let checkbox = value.permissLevel1;
          // 判断显示操作boolean的值
          if (checkbox) {
            //显示操作Boolean值为true，所有子级的显示状态值为true
            this.siteCheckboxRecursion(value.items, -1, checkbox, 1, 1);
            // 判断当前修改层级，层级：建筑物或站点
            value.permissLevel = 1;
          } else {
            //显示操作boolean的值为false时，所有子级显示/操作/设置状态全部为false
            value.permissLevel2 = checkbox;
            value.permissLevel3 = checkbox;
            value.permissType = 0;
            this.siteCheckboxRecursion(value.items, 0, checkbox, 1, 0);
            // 判断当前修改层级，层级：建筑物或站点
            value.permissLevel = 0;
          }
        } else if (type == 2) {
          //子级所有的操作状态值和父级一致
          let checkbox = value.permissLevel2;
          if (checkbox) {
            value.permissLevel = 2;
            this.siteCheckboxRecursion(value.items, 0, checkbox, 2);
          } else {
            value.permissLevel = 1;
            this.siteCheckboxRecursion(value.items, 0, checkbox, 1);
          }
        } else if (type == 3) {
          //子级所有的操作状态值和父级一致
          let checkbox = value.permissLevel3;
          this.siteCheckboxRecursion(value.items, 0, checkbox, 3);
          if (checkbox) {
            value.permissLevel = 3;
            this.siteCheckboxRecursion(value.items, 0, checkbox, 3, 3);
          } else {
            value.permissLevel = 2;
            this.siteCheckboxRecursion(value.items, 0, checkbox, 3, 2);
          }
        }
        clearInterval(times);
      }, 10);
    },
    // 站点设置多选框选择操作遍历函数
    siteCheckboxRecursion(value, num, checkbox, type, permissLevel) {
      for (let i = 0; i < value.length; i++) {
        if (type == 1) {
          if (checkbox) {
            value[i].permissLevel1 = checkbox;
            value[i].permissLevel2 = !checkbox;
            value[i].permissLevel3 = !checkbox;
            value[i].permissType = num;
            value[i].permissLevel = permissLevel;
          } else {
            value[i].permissLevel1 = checkbox;
            value[i].permissLevel2 = checkbox;
            value[i].permissLevel3 = checkbox;
            value[i].permissType = num;
            value[i].permissLevel = permissLevel;
          }
        } else if (type == 2) {
          value[i].permissLevel2 = checkbox;
          value[i].permissLevel = permissLevel;
        } else if (type == 3) {
          value[i].permissLevel3 = checkbox;
          value[i].permissLevel = permissLevel;
        }
        if (value[i].items !== undefined) {
          this.siteCheckboxRecursion(
            value[i].items,
            num,
            checkbox,
            type,
            permissLevel
          );
        }
      }
    },
    // 站点设置数据更新
    async siteSetDataUpate() {
      // console.log(this.data);
      this.siteSetLoading = true;
      this.siteGrIds = [];
      this.siteIds = [];
      await this.siteUpdateDataOperation(this.data[0].items);
      console.log(this.siteGrIds);
      console.log(this.siteIds);
      const body = {
        siteIds: this.siteIds,
        siteGrIds: this.siteGrIds,
      };
      const query = {
        clientId: this.clientValue,
        userGrId: this.basicsInfo.id,
      };
      console.log(body, query);
      setusergrsiteandsitegrpermision(body, query)
        .then((res) => {
          console.log(res);
          this.siteSetLoading = false;
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
            this.siteSetCancel();
            this.getClientData(this.basicsInfo);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.siteSetLoading = false;
        });
    },
    // 站点设置数据更新数据操作
    siteUpdateDataOperation(value) {
      const _val = JSON.stringify(value);
      const val = JSON.parse(_val);
      for (let i = 0; i < val.length; i++) {
        if (val[i].permissLevel > 0) {
          if (val[i].treeLevel == "building") {
            this.siteGrIds.push([val[i].id, val[i].permissLevel]);
          } else if (val[i].treeLevel == "site") {
            this.siteIds.push([val[i].id, val[i].permissLevel]);
          }
        } else {
          if (val[i].items !== undefined) {
            this.siteUpdateDataOperation(val[i].items);
          }
        }
      }
    },
    // 打开新建用户组弹窗
    openUserSet() {
      this.userSetVisible = true;
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#customercenter {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px #bdbcbc;
  position: relative;
}
.interface {
  width: 100%;
  height: 100%;
  padding: 10px 20px 20px 20px;
  background-color: #ffffff;
  overflow: hidden;
}
.pageTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.2);
  padding-bottom: 10px;
}
.pageTitle-left{
  display: flex;
  align-items: center;
}
.pageTitle img{
  width: 45px;
  margin-right: 10px;
}
.main {
  display: flex;
  height: calc(100% - 52.25px);
  padding-top: 20px;
}
.sidebar {
  height: 100%;
}
.sidebar_row {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: rgba(12, 12, 12, 0.65);
}
.sidebar_row span {
  margin-right: 10px;
}
.sidebar_userlist1 {
  width: 250px;
  height: calc(100% - 106px);
  overflow: auto;
}
.sidebar_userlist2 {
  width: 250px;
  height: calc(100% - 64px);
  overflow: auto;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 250px);
}
.index {
  width: calc(100% - 250px);
  padding-left: 20px;
}
.index_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.index_header_left {
  display: flex;
  align-items: center;
}
.index_header_card {
  display: flex;
  align-items: center;
  width: 300px;
  height: 80px;
  padding: 15px;
  margin-right: 30px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  background-color: #7682ce;
}
.index_header_card img {
  width: 48px;
  margin-right: 15px;
}
.index_heade_card_title {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.index_header_card_sign {
  padding: 0px 10px;
  border-radius: 3px;
  margin-left: 20px;
  color: #ffffff;
  background-color: #00cc00;
}
.index_header_info {
  display: flex;
}
.index_header_info_chunk {
  font-size: 14px;
  padding: 0px 40px 0px 20px;
}
.index_header_info_chunk p {
  margin-bottom: 5px;
}
.line {
  border-left: 1px solid rgba(12, 12, 12, 0.2);
}
.index_header_right {
  display: flex;
  flex-direction: column;
}
.index_main {
  height: calc(100% - 80px);
  padding-top: 20px;
  overflow: hidden;
}
.index_site {
  height: 50%;
}
.index_user1 {
  height: 100%;
}
.index_user2 {
  height: 50%;
}
.index_site_main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 53px);
  padding: 20px 20px 0px 0px;
  overflow: auto;
}
.index_site_main_card {
  width: 200px;
  height: 160px;
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: #f2f4f7;
  box-shadow: 0px 0px 10px -2px #bdbcbc;
}
.index_site_main_card_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.index_site_main_card_title span {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}
.p_title {
  margin-bottom: 5px;
  color: #7682ce;
}
.margin_bottom {
  margin-bottom: 10px;
}

.index_user_main {
  width: 100%;
  height: calc(100% - 53px);
  padding: 20px 20px 0px 0px;
  overflow: auto;
}
.index_user_main_row {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 20px;
  background-color: #f2f4f7;
  box-shadow: 0px 0px 10px -2px #bdbcbc;
  overflow: hidden;
}
.index_user_main_row_user {
  display: flex;
  align-items: center;
}
.index_user_main_row_user_img {
  margin-right: 30px;
}
.suer_info_container {
  width: 250px;
}
.suer_info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.suer_info_name {
  color: #7682ce;
  font-size: 16px;
  margin-right: 20px;
}
.img_email {
  margin-right: 10px;
}
.index_user_info_chunk {
  padding: 0px 15px;
  text-align: center;
}
.index_user_info_chunk p {
  font-size: 16px;
  margin-bottom: 5px;
}
.index_user_info_chunk span {
  font-size: 14px;
  color: rgba(12, 12, 12, 0.65);
}
.index_user_main_row_icon {
  display: flex;
  align-items: center;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.siteSetmain {
  height: 300px;
  padding: 20px;
  overflow: auto;
}
.empty_card {
  width: 200px;
  height: 1px;
}
.suerSet {
  height: 450px;
  padding: 20px 20px 20px 30px;
  overflow: auto;
}
div /deep/.ant-modal-body {
  padding: 0px;
}
div /deep/.ant-row.ant-form-item {
  margin: 0px;
}
.padding {
  padding: 20px;
}
.newUser {
  height: 500px;
  overflow: auto;
}
.newUser_title {
  padding: 20px 20px 0px 20px;
  margin-bottom: 12px;
}
.newUser_name {
  margin-bottom: 15px;
}
.newUser_main {
  height: calc(100% - 130px);
  padding: 0px 20px;
  overflow: auto;
}
</style>