<!--设置管理模块--通知设置管理页--帐号设置窗口-->
<template>    
  <a-form-model ref="ruleForm" :model="updateUser" layout='horizontal' :label-col="labelCol" :wrapper-col="wrapperCol">   
      <a-form-model-item ref="name" :label="$t('accountModal.a1')" prop="name" style="margin-bottom:8px;">
        <span>{{updateUser.username}}</span>
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a2')" prop="name" style="margin-bottom:8px;">
        <span>{{updateUser.username}}</span>
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a3')" prop="name" style="margin-bottom:8px;">
        <a-input v-model="updateUser.userid"  />
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a4')" prop="name" style="margin-bottom:8px;">
        <span>{{updateUser.userid}}</span>
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a5')" prop="name" style="margin-bottom:8px;">
        <a-input v-model="updateUser.ninsho"  />
        <a-radio-group name="radioGroup" :default-value="1">
          <a-radio :value="1">
            {{$t('accountModal.a6')}}
          </a-radio>
          <a-radio :value="2">
            {{$t('accountModal.a7')}}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a8')" prop="name" style="margin-bottom:8px;">
        <span>{{updateUser.level}}</span>
      </a-form-model-item>
      <a-form-model-item ref="name" label="Email" prop="name" style="margin-bottom:8px;">
        <a-input v-model="updateUser.mail"  />
        <a-button @click="sendMailClick">{{$t('accountModal.a9')}}</a-button>
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a10')" prop="name" style="margin-bottom:8px;">
        <a-button @click="passwordChangeClick">{{$t('accountModal.a11')}}</a-button>
        <a-modal 
            v-model="passwordVisible" 
            :title="passwordName" 
            width=600px           
            @ok="passwordHandleOk"
          >
            <my-password :userPassword="userPassword"></my-password>
        </a-modal>
        <span>{{$t('accountModal.a12')}}</span>
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a13')" prop="name" style="margin-bottom:8px;">
        <span>{{updateUser.kigen}}</span>
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('accountModal.a14')" prop="name" style="margin-bottom:8px;" >
        <a-checkbox v-model="updateUser.hismail">
          {{$t('accountModal.a15')}}
        </a-checkbox>
      </a-form-model-item>
      <div>        
        <span style="margin-left:80px;">{{$t('accountModal.a16')}}</span>
        <a-input v-model="updateUser.keihou_term"  style="width:40px;"/>
        <span>{{$t('accountModal.a17')}}</span>
      </div>
      
  </a-form-model>   
</template>

<script>
import passwordModal from './passwordModal';
import {checkPassword,sendMail} from "../../../api/user";

export default { 
  props: {
    updateUser: {
      type: Object,
    },
  }, 
  data(){
    return {
      userPassword:{
        now_p:"",
        new_p:"",
        new_p1Confirm:"",
        now_p2:"",
        new_p2:"",
        new_p2Confirm:"",
        uid:this.updateUser.id,
      },
      passwordName:this.$t('accountModal.a18'),
      passwordVisible:false,//是否显示密码修改对话框
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
    }
  },
  methods: {
    sendMailClick(){
      this.sendMail();
    },
    passwordChangeClick(){
      this.passwordVisible = true;
    },
    passwordHandleOk(){
      this.checkPassword();
    },    
    sendMail(){
      let par = {
        mailAddress:this.updateUser.mail,
        mailType:0,
      }
      console.log("sendMail par:", par); 
      sendMail(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("sendMail data", data);
          if(!data){            
            this.$error({
              title: this.$t('accountModal.a19'),
              content: msg,
              centered: true,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    checkPassword(){
      console.log("checkPassword par:", this.userPassword); 
      checkPassword(this.userPassword)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("checkPassword data", data);
          if(data>0){
            this.passwordVisible = true;
            let msg = "";
            switch(data){
              case 1://现和新相同
                msg =this.$t('accountModal.a20');
                break;
              case 2://现错误
                msg =this.$t('accountModal.a21');
                break;
              case 3://禁止文字
                msg =this.$t('accountModal.a22');
                break;
              case 4://太长
                msg =this.$t('accountModal.a23');
                break;
              case 999://其他错误
                msg =this.$t('accountModal.a24');
                break;
            }
            this.$error({
              title: this.$t('accountModal.a25'),
              content: msg,
              centered: true,
            });
          }
          else
            this.passwordVisible = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  components: {
    "my-password":passwordModal,
  },
  
};
</script>

<style scoped>
</style>