<!--信息通知主页-->
<template>
  <div id="infoHome">
    <div class="header">
      <img src="../../../public/images/inform/inform01.png" />
      <span>{{$t('infoHome.a1')}}</span>
    </div>
    <div class="main">
      <a-modal 
          v-model="visible" 
          :title="selectInfo.dgName" 
          width=500px           
          @ok="handleOk"
        >
        <a-form-model ref="ruleForm" :model="selectInfo">           
          <div class="form_row">
            <div class="form_col">    
              <a-form-model-item  prop="date1">
                <a-textarea v-model= "selectInfo.information" placeholder="Basic usage" :rows="6" />
              </a-form-model-item> 
            </div>
          </div> 
          <div class="form_row">            
            <div class="form_col">
              <a-form-model-item >
                <p>{{$t('infoHome.a2')}}{{selectInfo.lastUpdate}}</p>
              </a-form-model-item>
            </div>
          </div>                      
        </a-form-model>
      </a-modal>   
      <a-collapse class="handbook" default-active-key="1" :bordered="false" expandIconPosition="right">        
        <a-collapse-panel key="1" :header="$t('infoHome.a3')">    
          <img  v-if= "commonInfoNew" slot="extra" src="../../../public/images/inform/inform07.png"/> 
          <p v-for="(item,index) in commonInfo" :value="item.id" :key="item.key" @click="onClickCommon(index)">
            <a-button type="link">{{item.lastUpdate+"  "+item.information}} </a-button>
          </p>       
        </a-collapse-panel>    
        <!-- <a-collapse-panel key="2" :header="$t('infoHome.a4')" >
        </a-collapse-panel>     -->
        <a-collapse-panel key="3" :header="$t('infoHome.a5')" :disabled="false">
          <img  v-if= "inquireInfoNew" slot="extra" src="../../../public/images/inform/inform07.png"/> 
          <p v-for="(item,index) in inquireInfo" :value="item.id" :key="item.key" @click="onClickInquire(index)">
            <a-button type="link">{{item.lastUpdate+"  "+item.inqury}} </a-button>
          </p>
        </a-collapse-panel>
        <a-collapse-panel key="4" :header="$t('infoHome.a6')">
          <img  v-if= "downloadInfoNew" slot="extra" src="../../../public/images/inform/inform07.png"/> 
          <p v-for="(item,index) in downloadInfo" :value="item.id" :key="item.key" @click="onClickDownload(index)">
            <a-button type="primary" shape="round" icon="download"> {{item.lastUpdate+"  "+item.downLoadName}} </a-button>
          </p>
        </a-collapse-panel>
        <a-collapse-panel key="5" :header="$t('infoHome.a7')" :show-arrow="false">
          <a-icon slot="extra" type="edit" @click="accountHandleClick" />
          <a-modal 
            v-model="accountVisible" 
            :title="accountName" 
            width=600px           
            @ok="accountHandleOk"
          >
            <my-account :updateUser="updateUser"></my-account>
          </a-modal>
        </a-collapse-panel>        
        <a-collapse-panel key="6" :header="$t('infoHome.a8')" :show-arrow="false">
          <p v-for="(item,index) in transferInfo" :value="item.id" :key="item.key" @click="onClickTransfer(index)">
            <a-button type="link">{{item.lastUpdate+"  "+item.transfer}} </a-button>
          </p>
          <a-icon slot="extra" type="plus-square" @click="handleClick" />
          <a-modal 
            v-model="transferVisible" 
            :title="infoForm.desc" 
            width=600px           
            @ok="transferHandleOk"
          >
            <my-edit :infoForm="infoForm"></my-edit>
          </a-modal>
        </a-collapse-panel>

      </a-collapse>
    </div>   
  </div>
</template>

<script>
import moment from 'moment';
import editModal from '../setManagement/notificationSetting/editModal';
import accountModal from '../setManagement/notificationSetting/accountModal';
import {getInformation,addInformation,downloadFile} from "../../api/sysmanage";
import {getUser,updateUser} from "../../api/user";
import { downloadUrl } from "../../utils/utils";

// const newImg = {
//   template: `
//     <img src="../../../public/images/inform/inform07.png"/> 
//   `,
// };

export default {
  data() {
    return {
      loading: false, 
      commonInfo:[], 
      downloadInfo:[],
      inquireInfo:[],
      transferInfo:[], 
      commonInfoNew:false,//是否有新信息
      downloadInfoNew:false,
      inquireInfoNew:false,
      transferInfoNew:false,
      infoForm:{
        oldId:"0",//旧信息ID
        msg: "",//信息内容
        ranges:[moment(),moment().endOf("month")],//日期时间
        important: false,//是否重要信息
        isAdd:true,//是否新增
        desc:this.$t('infoHome.a8'),
      },
      selectInfo:{
        lastUpdate:"",
        information:"",
        dgName:this.$t('infoHome.a9'), 
      }, 
      updateUser:{},
      visible:false,  //弹出窗是否显示（显示详情）
      transferVisible:false,//弹出窗是否显示（添加交接事项）
      accountVisible:false,//弹出窗是否显示（帐号设置）
      accountName:this.$t('infoHome.a10'),
      newImg:{
        template: `
          <img src="../../../public/images/inform/inform07.png"/> 
        `,
      },

    };
  },  
  methods: {
    //帐号修改
    accountHandleClick(){
      this.getUserById();
      this.accountVisible = true;
    },
    accountHandleOk(){
      this.updateUserInfo();      
    } ,
    //输入交接事项
    handleClick(){//添加图标点击响应
      this.transferVisible = true;
    },
    transferHandleOk(){
      this.addInformation();
      this.transferVisible = false;
    } ,    
    onClickCommon(index){
      this.selectInfo.information = this.commonInfo[index].information;
      this.selectInfo.lastUpdate = this.commonInfo[index].lastUpdate;
      this.selectInfo.dgName =this.$t('infoHome.a3');
      this.visible = true;
    },
    onClickInquire(index){
      this.selectInfo.information = this.inquireInfo[index].inqury;
      this.selectInfo.lastUpdate = this.inquireInfo[index].lastUpdate;
      this.selectInfo.dgName = this.$t('infoHome.a5');
      this.visible = true;
    },
    onClickTransfer(index){
      this.selectInfo.information = this.transferInfo[index].transfer;
      this.selectInfo.lastUpdate = this.transferInfo[index].lastUpdate;
      this.selectInfo.dgName = this.$t('infoHome.a8');
      this.visible = true;
    },
    onClickDownload(index){
      this.downloadFile(index);      
    },
    handleOk(){
      this.visible = false;
    } , 
    cancel(){
    },

    //下载文件
    downloadFile(index){
      let par = {
        id:this.downloadInfo[index].key,
        downLoadName:this.downloadInfo[index].downLoadName,
      };
      console.log("downloadFile par:", par);
      downloadFile(par)
        .then((res) => {
          this.loading = false;
          console.log("downloadFile data:", res);
          downloadUrl(res,this.downloadInfo[index].fileName);  
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    // 添加
    addInformation() {           
      this.loading = true;  
      let infoPar = {
          oldId:this.infoForm.oldId,//旧信息ID
          msg: this.infoForm.msg,//信息内容
          infoType:3,//1：公共指南；2：咨询处；3：继承事项；4：下载；
          from:moment(this.infoForm.ranges[0]).format("YYYY-MM-DD"),//开始时间
          to:moment(this.infoForm.ranges[1]).format("YYYY-MM-DD"),//结束时间
          juyokbn:this.infoForm.important?"1":"0",//是否重要信息
        
      };
      console.log("addInformation par:", infoPar); 
      addInformation(infoPar)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("addInformation result", data);
          this.$message.info(this.$t('infoHome.a11')); 
          this.inquire(3);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('infoHome.a12')+err); 
        }); 
    },
    // 条件查询方法
    inquire(type) {           
      this.loading = true;
      let condition ="";
      if(type==4)//下载有2个参数，逗号分隔
        condition = ",";
      let par = {
        updateUser: "",
        infoType:type,//1：公共指南；2：咨询处；3：继承事项；4：下载；
        info: condition
      };
      // console.log("inquire par:", par); 
      getInformation(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;          
          if(data!=null && data.length>0){
            for (let i = 0; i < data.length; i++) {
              data[i].key = i + 1;
            }     
            let oldInfo=null;       
            switch(type){
              case 1:
                //比较是否有新消息
                oldInfo=localStorage.getItem("commonInfo");
                if(oldInfo!=null)
                  oldInfo=JSON.parse(oldInfo);                
                this.commonInfoNew=this.compareInfo(oldInfo,data);
                if(this.commonInfoNew){
                  console.log("inquire commonInfo data", data);
                  this.commonInfo = data;
                  localStorage.setItem("commonInfo",JSON.stringify(this.commonInfo));
                }
                else
                  this.commonInfo = oldInfo;
                //排除过期消息
                for (let i = 0; i < this.commonInfo.length; i++) {
                  this.commonInfo = this.commonInfo.filter((item) => {
                    return moment(item.dispStartDay)<=new moment() && moment(item.dispEndDay) >=new moment();
                  });
                }
                console.log("commonInfo filter data", this.commonInfo);
                break;
              case 2:
                //比较是否有新消息
                oldInfo=localStorage.getItem("inquireInfo");
                if(oldInfo!=null)
                  oldInfo=JSON.parse(oldInfo); 
                this.inquireInfoNew=this.compareInfo(oldInfo,data);
                if(this.inquireInfoNew){
                  this.inquireInfo = data;
                  localStorage.setItem("inquireInfo",JSON.stringify(this.inquireInfo));
                }
                else
                  this.inquireInfo = oldInfo;
                break;
              case 3:
                //比较是否有新消息
                oldInfo=localStorage.getItem("transferInfo");
                if(oldInfo!=null)
                  oldInfo=JSON.parse(oldInfo); 
                this.transferInfoNew=this.compareInfo(oldInfo,data);
                if(this.transferInfoNew){
                  this.transferInfo = data;
                  localStorage.setItem("transferInfo",JSON.stringify(this.transferInfo));
                }
                else
                  this.transferInfo = oldInfo;
                break;
              case 4:
                //比较是否有新消息
                oldInfo=localStorage.getItem("downloadInfo");
                if(oldInfo!=null)
                  oldInfo=JSON.parse(oldInfo); 
                this.downloadInfoNew=this.compareInfo(oldInfo,data);
                if(this.downloadInfoNew){
                  this.downloadInfo = data;
                  localStorage.setItem("downloadInfo",JSON.stringify(this.downloadInfo));
                }
                else
                  this.downloadInfo = oldInfo;
                break;
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    //对比判断是否有新消息
    compareInfo(oldInfo,data){
      
      if(data==null||data.length==0)
        return false;      
      if(oldInfo==null||oldInfo.length==0){
        if(data!=null&&data.length>0)
          return true;
      }
      else{        
        let isFound = false;
        for(let i=0;i<data.length;i++){
          isFound = false;
          for(let j=0;j<oldInfo.length;j++){
            if(data[i].hidden==oldInfo[j].hidden){   
              isFound = true;           
              break;
            }
          }
          if(!isFound)//找不到，表示有新消息
            return true;
        }
      }
      return false;         
    },
    //通过ID取用户信息
    getUserById(){
      let user = JSON.parse(this.$store.getters.user);
      console.log("getUserById user:", user); 
      let par = {
        id: user.id,
      };
      console.log("getUserById par:", par); 
      getUser(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("getUserById data", data);
          this.updateUser = data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },    
    //更新用户信息
    updateUserInfo(){
      console.log("updateUser par:", this.updateUser); 
      updateUser(this.updateUser)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateUser data", data);
          if(data>0){
            this.$info({
                title: this.$t('infoHome.a13'),
                content: this.$t('infoHome.a14'),
                centered: true,
              });  
            this.accountVisible = false;
          }else{               
            this.$error({
                title: this.$t('infoHome.a13'),
                content: this.$t('infoHome.a15'),
                centered: true,
              });   
            this.accountVisible = true;
          }  
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  // created(){
  //   //从本地存储读取数据
  //   this.commonInfo = localStorage.getItem("commonInfo");
  //   this.inquireInfo = localStorage.getItem("inquireInfo");
  //   this.transferInfo = localStorage.getItem("transferInfo");
  //   this.downloadInfo = localStorage.getItem("downloadInfo");
  // },  
  mounted(){    
    this.inquire(1);//1：公共指南；2：咨询处；3：继承事项；4：下载；
    this.inquire(2);//1：公共指南；2：咨询处；3：继承事项；4：下载；
    this.inquire(3);//1：公共指南；2：咨询处；3：继承事项；4：下载；
    this.inquire(4);//1：公共指南；2：咨询处；3：继承事项；4：下载；
  },
  components: {
    "my-edit":editModal,
    "my-account":accountModal,
  },
};
</script>

<style scoped>
#infoHome {
  width: 100%;
  height: 100%;
}
.header {
  height: 60px;
  padding: 20px 20px 0px 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.header span {
  margin-left: 15px;
  font-size: 20px;
}
.main {
  height: calc(100% - 60px);
  padding: 20px 20px 0px 20px;
  overflow: auto;
}
.handbook {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 4px;
  margin-bottom: 20px;
}
.handbook-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.handbook-header span {
  font-size: 16px;
  margin-left: 10px;
}
.handbook-main {
  background-color: #f2f4f7;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: rgba(12, 12, 12, 0.7);
  font-size: 13px;
  border-radius: 3px;
  margin-bottom: 15px;
}
.button {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
</style>