<!--设置管理模块--通知设置管理页--帐号设置密码修改窗口-->
<template>    
  <a-form-model ref="ruleForm" :model="userPassword">    
      <a-form-model-item ref="name" :label="$t('passwordModal.a1')" prop="name">
        <a-input v-model="userPassword.now_p"  />
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('passwordModal.a2')" prop="name">
        <a-input v-model="userPassword.new_p"  />
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('passwordModal.a3')" prop="name">
        <a-input v-model="userPassword.new_p1Confirm"  />
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('passwordModal.a4')" prop="name">
        <a-input v-model="userPassword.now_p2"  />
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('passwordModal.a5')" prop="name">
        <a-input v-model="userPassword.new_p2"  />
      </a-form-model-item>
      <a-form-model-item ref="name" :label="$t('passwordModal.a6')" prop="name">
        <a-input v-model="userPassword.new_p2Confirm"  />
      </a-form-model-item>
  </a-form-model>   
</template>

<script>

export default { 
  props: {
    userPassword: {
      type: Object,
    },
  },  
  
};
</script>

<style scoped>
</style>